import request from '@/utils/request'

export function fetchList(query) {
    return request({
        url: '/admin/photoedit/list',
        method: 'get',
        params: query
    })
}

export function setMajor(data) {
    return request({
        url: '/admin/photoedit/setMajor',
        method: 'post',
        data
    })
}

export function replace(data) {
    return request({
        url: '/admin/photoedit/replace',
        method: 'post',
        data
    })
}
