<template>
  <div class="picture-list">
    <div class="wrapper">
      <div class="screen">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>图片审核</el-breadcrumb-item>
        </el-breadcrumb>
        <el-radio-group v-model="tabPosition">
          <el-radio-button label="0">未审核</el-radio-button>
          <el-radio-button label="1">审核通过</el-radio-button>
          <el-radio-button label="2">审核未通过</el-radio-button>
          <el-radio-button label="">全部</el-radio-button>
        </el-radio-group>
      </div>
      <el-table :data="tableData" class="main-table" header-row-class-name="table-header"
                cell-class-name="table-hover" :row-class-name="tableRowClassName" @sort-change="handleSort"
                :default-sort="{prop: 'modifyTime', order: 'descending'}">
        <el-table-column label="序号" type="index" :index="indexMethod" width="60" align="center">
        </el-table-column>
        <el-table-column prop="buyCode" label="备件号" width="300"></el-table-column>
        <el-table-column prop="name" label="备件名称" width="300"></el-table-column>
        <el-table-column label="图片设置" align="center" class-name="table-operate">
          <template slot-scope="scope">
            <div v-for="(item,index) in scope.row.imgs" :key="index" class="picture-item">
              <div class="picture-uploader">
                <el-image fit="contain" :src="item.url" class="img"
                          :class="{'active':item.major == 1}" @click.stop="previewImg(item)">
                </el-image>
                <div class="btn">
                  <el-button size="small" type="primary" @click.stop="mainPicture(item, scope.row.id)"
                             title="设置主图">{{ item.major == 1 ? '取消' : '设置' }}
                  </el-button>
                  <el-button size="small" type="primary" @click.stop="del(item.id)">删除</el-button>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center" width="80">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.status == 1" effect="dark">已通过</el-tag>
            <el-tag type="info" v-if="scope.row.status == 0" effect="dark">未审核</el-tag>
            <el-tag type="danger" v-if="scope.row.status == 2" effect="dark">未通过</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="150">
          <template slot-scope="scope">
            <el-button v-if="scope.row.status == 0" size="small" type="primary" @click="submitClick(scope.row.id, 1)">通过</el-button>
            <el-button v-if="scope.row.status == 0" size="small" type="primary" @click="submitClick(scope.row.id, 2)">拒绝</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background layout="total, prev, pager, next" :total="this.total"
                     :current-page.sync="query.page" :page-size="query.pagesize" @current-change="getList">
      </el-pagination>
    </div>

    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import {fetchList, setMajor, del, check} from "@/api/uploadImgCheck";

export default {
  name: "examine",
  components: {},
  data() {
    return {
      header: {token: localStorage.getItem("token")},
      dialogVisible: false,
      dialogImageUrl: '',
      tabPosition: '0',
      tableData: [],
      query: {
        page: 1,
        pagesize: 10,
        keyword: '',
        status: '0'
      },
      total: ''
    };
  },
  watch: {
    tabPosition(val) {
      this.query.status = val;
      this.getList();
    }
  },
  created() {
    this.getList();
    document.addEventListener('visibilitychange', this.handleVisiable)
  },
  destroyed() {
    document.removeEventListener('visibilitychange', this.handleVisiable)
  },
  methods: {
    mainPicture(item, productId) {
      if (!item.major) {
        this.$confirm('确定将此图片设为主图吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          setMajor({id: item.id, major: 1}).then(() => {
            this.$message.success('设置成功');
            this.getList();
          }).catch(err => {
            this.$message.error(err.message);
          });
        }).catch(() => {

        });
      } else if (item.major == 1) {
        this.$confirm('确定取消主图?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          setMajor({id: item.id, major: 0, productId: productId}).then(() => {
            this.$message.success('设置成功');
            this.getList();
          }).catch(err => {
            this.$message.error(err.message);
          });
        }).catch(() => {

        });
      }

    },
    del(id) {
      this.$confirm('确定要删除此图片吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        del({id: id}).then(() => {
          this.$message.success('设置成功');
          this.getList();
        }).catch(err => {
          this.$message.error(err.message);
        });
      }).catch(() => {

      });
    },
    submitClick(id, status) {
      this.$confirm('确定要执行此操作吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        check({id: id, status: status}).then(() => {
          this.$message.success('操作成功');
          this.getList();
        }).catch(err => {
          this.$message.error(err.message);
        });
      }).catch(() => {

      });
    },
    previewImg(item) {
      this.dialogImageUrl = item.url;
      this.dialogVisible = true;
    },
    handleVisiable(e) {
      if (e.target.visibilityState === 'visible') {
        this.getList()
      }
    },
    indexMethod(e) {
      console.log(e)
      return (this.query.page - 1) * this.query.pagesize + e + 1
    },
    tableRowClassName({
                        rowIndex
                      }) {
      if (rowIndex % 2 == 0) {
        return "";
      } else {
        return "active-row";
      }
    },
    getList() {
      fetchList(this.query).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total;
      })
    },
    handleSort(e) {
      let field = e.prop
      if (field == 'modifyTime') {
        field = 'modify_time'
      }
      console.log(e);
      if (e.order == "ascending") {
        this.query.sort = "+" + field
      } else if (e.order == "descending") {
        this.query.sort = "-" + field
      } else {
        this.query.sort = ""
      }
      // this.query.page = 1
      this.getList()
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/css/picture-editing.scss";
</style>
